import { RouteConfig } from 'vue-router'
import { RouteEntry } from './interface'
import { re } from './routeEntries'

/** wrapper func to generate route config object */
const RG = (rn: RouteEntry, comp): RouteConfig => ({
  name: rn.id,
  path: rn.path,
  component: comp,
})

const Routes: Array<RouteConfig> = [
  { ...RG(re.index, undefined) }, // Index

  { ...RG(re.prihlaseni,            () => import('@/components/views/public/LoginView.vue'))          }, // Login
  { ...RG(re.zapomenute_heslo,      () => import('@/components/views/public/ForgottenPassword.vue'))  }, // Forgotten Password
  { ...RG(re.obnova_hesla,          () => import('@/components/views/public/PasswordReset.vue'))      }, // Password Reset
  { ...RG(re.potvrzeni_objednavky,  () => import('@/components/views/public/OrderPreview.vue'))       }, // Approve Order
  { ...RG(re.potvrzeni_servisu,  () => import('@/components/views/public/RepairPreview.vue'))       }, // Approve Repair
  { ...RG(re.editOrCreateOrder,     () => import('@/components/views/order/edit/OrderEditView.vue'))  },

  {
    ...RG(re.admin, () => import('@/components/views/AppView.vue')), // Auth part of the app
    children: [
      { ...RG(re.admin_objednavky,              () => import('@/components/views/admin/OrdersView.vue'))          },
      { ...RG(re.admin_detail_objednavky,       () => import('@/components/views/admin/OrderDetailView.vue'))     },
      { ...RG(re.admin_poptavky,                () => import('@/components/views/admin/InquiriesView.vue'))       },
      { ...RG(re.admin_detail_poptavky,         () => import('@/components/views/StubView.vue'))                  },
      { ...RG(re.admin_kos_pruseru,             () => import('@/components/views/admin/RepairRequestView.vue'))      },
      { ...RG(re.admin_detail_reklamace,        () => import('@/components/views/admin/RepairRequestDetail.vue')) },
      { ...RG(re.admin_nova_reklamace,        () => import('@/components/views/admin/RepairRequestNew.vue')) },
      { ...RG(re.admin_reklamacni_protokol,     () => import('@/components/views/admin/RepairRequestProtocol.vue'))      },
      { ...RG(re.admin_reklamacni_protokol_nahrat,     () => import('@/components/views/admin/RepairRequestProtocolUpload.vue'))      },
      { ...RG(re.admin_montazni_kalendar,       () => import('@/components/views/admin/AssemblyCalendar.vue'))    },
      { ...RG(re.admin_volno,                   () => import('@/components/views/StubView.vue'))                  },
      { ...RG(re.admin_montazni_skupiny,        () => import('@/components/views/admin/AssemblyGroupsView.vue'))  },
      { ...RG(re.admin_detail_montazni_skupiny, () => import('@/components/views/admin/AssemblyGroupDetail.vue')) },
      { ...RG(re.admin_pobocky,                 () => import('@/components/views/admin/BranchesView.vue'))        },
      { ...RG(re.admin_detail_pobocky,          () => import('@/components/views/admin/BranchOfficeDetail.vue'))  },
      { ...RG(re.admin_predavaci_protokol,      () => import('@/components/views/admin/AssemblyProtocol.vue'))    },
      { ...RG(re.admin_sklad,                   () => import('@/components/views/StubView.vue'))                  },
      { ...RG(re.admin_prevodky,                () => import('@/components/views/StubView.vue'))                  },
      { ...RG(re.admin_detail_prevodky,         () => import('@/components/views/StubView.vue'))                  },
      { ...RG(re.admin_uzivatele,               () => import('@/components/views/admin/UsersView.vue'))           },
      { ...RG(re.admin_nastaveni,               () => import('@/components/views/admin/SettingsView.vue'))           },
      { ...RG(re.admin_upload_dokumentu,        () => import('@/components/views/admin/OrderDocUpload.vue'))      },
    ],
  },
  {
    ...RG(re.montaznik, () => import('@/components/views/AppView.vue')), // Auth part of the app
    children: [
      { ...RG(re.worker_objednavky,         () => import('@/components/views/admin/OrdersView.vue'))          },
      { ...RG(re.worker_detail_objednavky,  () => import('@/components/views/admin/OrderDetailView.vue'))     },
      { ...RG(re.worker_kos_pruseru,        () => import('@/components/views/admin/RepairRequestView.vue'))  },
      { ...RG(re.worker_detail_reklamace,        () => import('@/components/views/admin/RepairRequestDetail.vue')) },
      { ...RG(re.worker_nova_reklamace,        () => import('@/components/views/admin/RepairRequestNew.vue')) },
      { ...RG(re.worker_reklamacni_protokol,     () => import('@/components/views/admin/RepairRequestProtocol.vue'))      },
      { ...RG(re.worker_reklamacni_protokol_nahrat,     () => import('@/components/views/admin/RepairRequestProtocolUpload.vue'))      },
      { ...RG(re.worker_montazni_kalendar,  () => import('@/components/views/admin/AssemblyCalendar.vue'))    },
      { ...RG(re.worker_predavaci_protokol, () => import('@/components/views/admin/AssemblyProtocol.vue'))    },
      { ...RG(re.worker_sklad,              () => import('@/components/views/StubView.vue'))                  },
      { ...RG(re.worker_volno,              () => import('@/components/views/StubView.vue'))                  },
      { ...RG(re.worker_vykazy,             () => import('@/components/views/StubView.vue'))                  },
      { ...RG(re.worker_detail_vykazu,      () => import('@/components/views/StubView.vue'))                  },
      { ...RG(re.worker_nastaveni,          () => import('@/components/views/admin/AssemblyGroupDetail.vue')) },
      { ...RG(re.worker_upload_dokumentu,   () => import('@/components/views/admin/OrderDocUpload.vue'))      },
    ],
  },
  {
    ...RG(re.pobocka, () => import('@/components/views/AppView.vue')), // Auth part of the app
    children: [
      { ...RG(re.branch_objednavky,         () => import('@/components/views/admin/OrdersView.vue'))       },
      { ...RG(re.branch_detail_objednavky,  () => import('@/components/views/admin/OrderDetailView.vue'))  },
      { ...RG(re.branch_poptavky,           () => import('@/components/views/admin/InquiriesView.vue'))    },
      { ...RG(re.branch_detail_poptavky,    () => import('@/components/views/StubView.vue'))               },
      { ...RG(re.branch_kos_pruseru,        () => import('@/components/views/admin/RepairRequestView.vue'))  },
      { ...RG(re.branch_detail_reklamace,        () => import('@/components/views/admin/RepairRequestDetail.vue')) },
      { ...RG(re.branch_nova_reklamace,        () => import('@/components/views/admin/RepairRequestNew.vue')) },
      { ...RG(re.branch_reklamacni_protokol,     () => import('@/components/views/admin/RepairRequestProtocol.vue'))      },
      { ...RG(re.branch_reklamacni_protokol_nahrat,     () => import('@/components/views/admin/RepairRequestProtocolUpload.vue'))      },
      { ...RG(re.branch_montazni_kalendar,  () => import('@/components/views/admin/AssemblyCalendar.vue')) },
      { ...RG(re.branch_vykazy,             () => import('@/components/views/StubView.vue'))               },
      { ...RG(re.branch_sklad,              () => import('@/components/views/StubView.vue'))               },
      { ...RG(re.branch_upload_dokumentu,   () => import('@/components/views/admin/OrderDocUpload.vue'))   },
      { ...RG(re.branch_montazni_skupiny,        () => import('@/components/views/admin/AssemblyGroupsView.vue'))  },
      { ...RG(re.branch_detail_montazni_skupiny, () => import('@/components/views/admin/AssemblyGroupDetail.vue')) },
      { ...RG(re.branch_predavaci_protokol, () => import('@/components/views/admin/AssemblyProtocol.vue')) },
      { ...RG(re.branch_pobocky,                 () => import('@/components/views/admin/BranchesView.vue'))        },
      { ...RG(re.branch_detail_pobocky,          () => import('@/components/views/admin/BranchOfficeDetail.vue'))  },
      { ...RG(re.branch_uzivatele,          () => import('@/components/views/admin/UsersView.vue'))  },
    ],
  },
]

Routes.push({ ...RG(re.notfound, () => import('@/components/views/public/NotFound.vue')) }) // NotFound

export default Routes
