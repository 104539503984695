import Vue from 'vue'

let vueInstance: Vue

export const setVueInstance = (instance: Vue) => {
  vueInstance = instance
}

export const useToast = () => {
  if (!vueInstance) {
    console.warn('Vue instance not set. Toast messages will not be displayed.')
    return {
      success: (message: string) => console.log('Success:', message),
      error: (message: string) => console.error('Error:', message),
      // Add other toast methods as needed
    }
  }

  return vueInstance.$toast
}
